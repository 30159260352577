<template>
  <div class="w-full">
    <sun-label-group :text="title" class="w-full">
      <asterix-input
        v-model="subPublisherInput"
        class="rounded-r-none"
        type="text"
        :maxlength="250"
        placeholder="Lock a subpublisher..."
        @enter="addItemToList"
      />
      <sun-button class="custom-p-1 text-xs rounded-l-none" :disabled="addButtonDisabled" @click="addItemToList">
        Add
      </sun-button>
    </sun-label-group>
    <div v-if="tagList.length" class="w-full">
      <p class="w-full text-sm mt-5 mb-1">{{ subTitle }}</p>
      <div class="w-full flex flex-wrap border rounded">
        <div
          v-for="(item, index) in tagList"
          :key="`item_${index}`"
          class="rounded-full border flex items-center pl-2 m-1 bg-red-200"
        >
          <p class="w-full whitespace-no-wrap">{{ item }}</p>
          <div
            class="flex items-center rounded-r-full cursor-pointer w-full h-full ml-2 pr-2"
            @click="deleteItemFromList(item)"
          >
            <close-svg class="w-2 text-gray-700" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AsterixInput from '@/components/atoms/AsterixInput';
import CloseSvg from '@/components/icons/CloseSvg';
import { deepClone } from '@/utils/deepClone';

export default {
  name: 'AsterixTagCreator',
  components: { AsterixInput, CloseSvg },
  props: {
    title: {
      type: String,
      default: null,
    },
    subTitle: {
      type: String,
      default: null,
    },
    defaultTagList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      subPublisherInput: null,
      tagList: null,
    };
  },
  computed: {
    addButtonDisabled() {
      return this.tagList.includes(this.subPublisherInput) || !this.subPublisherInput;
    },
  },
  created() {
    this.tagList = deepClone(this.defaultTagList) || [];
  },
  methods: {
    addItemToList() {
      if (!this.addButtonDisabled) {
        this.tagList.push(this.subPublisherInput);
        this.subPublisherInput = null;
        this.emitList();
      }
    },
    deleteItemFromList(item) {
      this.tagList = this.tagList.filter(element => element !== item);
      this.emitList();
    },
    emitList() {
      this.$emit('change', this.tagList);
    },
  },
};
</script>
