var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-form-modal", {
    attrs: { title: _vm.title, closable: "" },
    on: {
      submit: _vm.onAddPublisherSubmit,
      cancel: function ($event) {
        return _vm.$emit("cancel")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "px-4 pb-6",
                class: { "h-32": !_vm.selectedPublisher },
              },
              [
                _c(
                  "sun-label-group",
                  { staticClass: "w-full", attrs: { text: "Publisher" } },
                  [
                    _c("asterix-async-select", {
                      class: { "h-48": _vm.isSelectOpened },
                      attrs: {
                        value: _vm.selectedPublisher,
                        disabled: _vm.editingPublisher,
                        name: "publisher-selector",
                        service: _vm.getPublishersService,
                        "disabled-items": _vm.publishers,
                      },
                      on: {
                        focus: function ($event) {
                          return _vm.expandModalHeight()
                        },
                        blur: function ($event) {
                          return _vm.collapseModalHeight()
                        },
                        change: function ($event) {
                          _vm.selectedPublisher = $event.items
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm.selectedPublisher
                  ? _c(
                      "asterix-radio-group",
                      { staticClass: "mt-10", attrs: { column: "" } },
                      [
                        _c("asterix-radio-input", {
                          staticClass: "mb-4 items-baseline",
                          attrs: {
                            name: "commission",
                            "input-value": _vm.commissionTypes.DEFAULT,
                          },
                          on: { change: _vm.resetPriceAndCommission },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex flex-col md:flex-row align-center w-full",
                                      },
                                      [
                                        _vm.isPercentageOffer
                                          ? _c(
                                              "p",
                                              { staticClass: "w-full mt-2" },
                                              [_vm._v("Our Margin (Default)")]
                                            )
                                          : _c(
                                              "p",
                                              { staticClass: "w-full mt-2" },
                                              [
                                                _vm._v(
                                                  "Our Publisher Default Commission"
                                                ),
                                              ]
                                            ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "w-full md:w-64 relative",
                                          },
                                          [
                                            _c("asterix-input", {
                                              staticClass: "w-64",
                                              attrs: {
                                                name: "defaultCommission",
                                                value:
                                                  _vm.selectedPublisher
                                                    .commissionValue,
                                                "auto-validate": "",
                                                "class-input": {
                                                  "forced-gray":
                                                    !_vm.isDefaultCommission,
                                                },
                                                disabled: "",
                                                max: 100,
                                                min: 0,
                                                type: "number",
                                                "validate-on-blur": "",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "absolute right-0 top-0 flex items-center h-10 w-8 appearance-none text-gray-500",
                                              },
                                              [_vm._v("%")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1621626227
                          ),
                          model: {
                            value: _vm.selectedCommissionType,
                            callback: function ($$v) {
                              _vm.selectedCommissionType = $$v
                            },
                            expression: "selectedCommissionType",
                          },
                        }),
                        _c("asterix-radio-input", {
                          staticClass: "mb-4 items-baseline",
                          attrs: {
                            name: "commission",
                            "input-value": _vm.commissionTypes.PERCENT,
                          },
                          on: { change: _vm.resetPriceAndCommission },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex flex-col md:flex-row align-center w-full",
                                      },
                                      [
                                        _vm.isPercentageOffer
                                          ? _c(
                                              "p",
                                              { staticClass: "mt-2 w-full" },
                                              [_vm._v("Publisher Commission")]
                                            )
                                          : _c(
                                              "p",
                                              { staticClass: "mt-2 w-full" },
                                              [
                                                _vm._v(
                                                  "Our Publisher Offer Commission"
                                                ),
                                              ]
                                            ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "w-full md:w-64 relative",
                                          },
                                          [
                                            _c("asterix-input", {
                                              attrs: {
                                                disabled:
                                                  !_vm.isPercentCommission,
                                                "class-input": "pr-10",
                                                type: "number",
                                                name: "percentCommission",
                                                min: 0,
                                                max: 100,
                                                placeholder: "50",
                                              },
                                              model: {
                                                value: _vm.percentCommission,
                                                callback: function ($$v) {
                                                  _vm.percentCommission = $$v
                                                },
                                                expression: "percentCommission",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "absolute right-0 top-0 flex items-center h-10 w-8 appearance-none text-gray-500",
                                              },
                                              [_vm._v("%")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            701218526
                          ),
                          model: {
                            value: _vm.selectedCommissionType,
                            callback: function ($$v) {
                              _vm.selectedCommissionType = $$v
                            },
                            expression: "selectedCommissionType",
                          },
                        }),
                        !_vm.isPercentageOffer
                          ? _c("asterix-radio-input", {
                              staticClass: "mb-4 items-baseline",
                              attrs: {
                                name: "commission",
                                "input-value": _vm.commissionTypes.FIX_PRICE,
                              },
                              on: { change: _vm.resetPriceAndCommission },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex flex-col md:flex-row align-center w-full",
                                          },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "w-full mt-2" },
                                              [
                                                _vm._v(
                                                  "Publisher Fix Price Commission"
                                                ),
                                              ]
                                            ),
                                            _c("currency-selector", {
                                              key: _vm.selectedCommissionType,
                                              staticClass: "w-full md:w-64",
                                              attrs: {
                                                name: "fixPrice",
                                                placeholder: "0.51",
                                                "default-currency": "EUR",
                                                disabled:
                                                  !_vm.isFixPriceCommission,
                                                required:
                                                  _vm.isFixPriceCommission,
                                                "currency-disabled": "",
                                              },
                                              model: {
                                                value: _vm.fixPrice,
                                                callback: function ($$v) {
                                                  _vm.fixPrice = $$v
                                                },
                                                expression: "fixPrice",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1806842991
                              ),
                              model: {
                                value: _vm.selectedCommissionType,
                                callback: function ($$v) {
                                  _vm.selectedCommissionType = $$v
                                },
                                expression: "selectedCommissionType",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedPublisher
                  ? _c("asterix-tag-creator", {
                      staticClass: "mt-2",
                      attrs: {
                        title: "Subpublisher locks",
                        "sub-title": "Manual Actions",
                        "default-tag-list":
                          _vm.offerPublisher?.subPublishersLockedList,
                      },
                      on: { change: _vm.onSubPublisherLockedChange },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "sm:flex sm:flex-row justify-between px-4" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex w-full rounded-md shadow-sm sm:mr-3 sm:w-auto",
                  },
                  [
                    _c(
                      "sun-button",
                      {
                        staticClass: "custom-p-1 text-xs w-full",
                        attrs: {
                          variant: "pill",
                          type: "submit",
                          color: "orange",
                          disabled: !_vm.isFormValid,
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.editingPublisher
                                ? "Save Changes"
                                : "Add Publisher"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto",
                  },
                  [
                    _c(
                      "sun-button",
                      {
                        staticClass: "custom-p-1 text-xs w-full",
                        attrs: { variant: "pill", color: "white" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("cancel")
                          },
                        },
                      },
                      [_vm._v(" Cancel ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }