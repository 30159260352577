var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _c(
        "sun-label-group",
        { staticClass: "w-full", attrs: { text: _vm.title } },
        [
          _c("asterix-input", {
            staticClass: "rounded-r-none",
            attrs: {
              type: "text",
              maxlength: 250,
              placeholder: "Lock a subpublisher...",
            },
            on: { enter: _vm.addItemToList },
            model: {
              value: _vm.subPublisherInput,
              callback: function ($$v) {
                _vm.subPublisherInput = $$v
              },
              expression: "subPublisherInput",
            },
          }),
          _c(
            "sun-button",
            {
              staticClass: "custom-p-1 text-xs rounded-l-none",
              attrs: { disabled: _vm.addButtonDisabled },
              on: { click: _vm.addItemToList },
            },
            [_vm._v(" Add ")]
          ),
        ],
        1
      ),
      _vm.tagList.length
        ? _c("div", { staticClass: "w-full" }, [
            _c("p", { staticClass: "w-full text-sm mt-5 mb-1" }, [
              _vm._v(_vm._s(_vm.subTitle)),
            ]),
            _c(
              "div",
              { staticClass: "w-full flex flex-wrap border rounded" },
              _vm._l(_vm.tagList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: `item_${index}`,
                    staticClass:
                      "rounded-full border flex items-center pl-2 m-1 bg-red-200",
                  },
                  [
                    _c("p", { staticClass: "w-full whitespace-no-wrap" }, [
                      _vm._v(_vm._s(item)),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex items-center rounded-r-full cursor-pointer w-full h-full ml-2 pr-2",
                        on: {
                          click: function ($event) {
                            return _vm.deleteItemFromList(item)
                          },
                        },
                      },
                      [_c("close-svg", { staticClass: "w-2 text-gray-700" })],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }