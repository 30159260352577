<template>
  <asterix-form-modal :title="title" closable @submit="onAddPublisherSubmit" @cancel="$emit('cancel')">
    <template #content>
      <div class="px-4 pb-6" :class="{ 'h-32': !selectedPublisher }">
        <sun-label-group class="w-full" text="Publisher">
          <asterix-async-select
            :value="selectedPublisher"
            :disabled="editingPublisher"
            name="publisher-selector"
            :class="{ 'h-48': isSelectOpened }"
            :service="getPublishersService"
            :disabled-items="publishers"
            @focus="expandModalHeight()"
            @blur="collapseModalHeight()"
            @change="selectedPublisher = $event.items"
          />
        </sun-label-group>
        <asterix-radio-group v-if="selectedPublisher" column class="mt-10">
          <asterix-radio-input
            v-model="selectedCommissionType"
            name="commission"
            class="mb-4 items-baseline"
            :input-value="commissionTypes.DEFAULT"
            @change="resetPriceAndCommission"
          >
            <template #label>
              <div class="flex flex-col md:flex-row align-center w-full">
                <p v-if="isPercentageOffer" class="w-full mt-2">Our Margin (Default)</p>
                <p v-else class="w-full mt-2">Our Publisher Default Commission</p>
                <div class="w-full md:w-64 relative">
                  <asterix-input
                    name="defaultCommission"
                    :value="selectedPublisher.commissionValue"
                    auto-validate
                    class="w-64"
                    :class-input="{ 'forced-gray': !isDefaultCommission }"
                    disabled
                    :max="100"
                    :min="0"
                    type="number"
                    validate-on-blur
                  />
                  <span class="absolute right-0 top-0 flex items-center h-10 w-8 appearance-none text-gray-500">%</span>
                </div>
              </div>
            </template>
          </asterix-radio-input>

          <asterix-radio-input
            v-model="selectedCommissionType"
            name="commission"
            class="mb-4 items-baseline"
            :input-value="commissionTypes.PERCENT"
            @change="resetPriceAndCommission"
          >
            <template #label>
              <div class="flex flex-col md:flex-row align-center w-full">
                <p v-if="isPercentageOffer" class="mt-2 w-full">Publisher Commission</p>
                <p v-else class="mt-2 w-full">Our Publisher Offer Commission</p>
                <div class="w-full md:w-64 relative">
                  <asterix-input
                    v-model="percentCommission"
                    :disabled="!isPercentCommission"
                    class-input="pr-10"
                    type="number"
                    name="percentCommission"
                    :min="0"
                    :max="100"
                    placeholder="50"
                  />
                  <span class="absolute right-0 top-0 flex items-center h-10 w-8 appearance-none text-gray-500">%</span>
                </div>
              </div>
            </template>
          </asterix-radio-input>
          <asterix-radio-input
            v-if="!isPercentageOffer"
            v-model="selectedCommissionType"
            name="commission"
            class="mb-4 items-baseline"
            :input-value="commissionTypes.FIX_PRICE"
            @change="resetPriceAndCommission"
          >
            <template #label>
              <div class="flex flex-col md:flex-row align-center w-full">
                <p class="w-full mt-2">Publisher Fix Price Commission</p>
                <currency-selector
                  :key="selectedCommissionType"
                  v-model="fixPrice"
                  name="fixPrice"
                  class="w-full md:w-64"
                  placeholder="0.51"
                  default-currency="EUR"
                  :disabled="!isFixPriceCommission"
                  :required="isFixPriceCommission"
                  currency-disabled
                />
              </div>
            </template>
          </asterix-radio-input>
        </asterix-radio-group>
        <asterix-tag-creator
          v-if="selectedPublisher"
          class="mt-2"
          title="Subpublisher locks"
          sub-title="Manual Actions"
          :default-tag-list="offerPublisher?.subPublishersLockedList"
          @change="onSubPublisherLockedChange"
        />
      </div>
    </template>
    <template #footer>
      <div class="sm:flex sm:flex-row justify-between px-4">
        <div class="flex w-full rounded-md shadow-sm sm:mr-3 sm:w-auto">
          <sun-button
            variant="pill"
            type="submit"
            class="custom-p-1 text-xs w-full"
            color="orange"
            :disabled="!isFormValid"
          >
            {{ editingPublisher ? 'Save Changes' : 'Add Publisher' }}
          </sun-button>
        </div>
        <div class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <sun-button variant="pill" class="custom-p-1 text-xs w-full" color="white" @click="$emit('cancel')">
            Cancel
          </sun-button>
        </div>
      </div>
    </template>
  </asterix-form-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import AsterixFormModal from '@/components/organisms/shared/AsterixFormModal';
import AsterixInput from '@/components/atoms/AsterixInput';
import AsterixRadioGroup from '@/components/molecules/shared/AsterixRadioGroup.vue';
import OfferPublisher from '@/entities/ecommerce/OfferPublisher';
import AsterixRadioInput from '@/components/atoms/AsterixRadioInput';
import { create as newPublisherRoute } from '@/router/private/modules/ecommerce/supply/publisher/create';
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect';
import { getPublishers } from '@/services/modules/ecommerce/publisher';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import FILTERS_CONFIG from '@/model/modules/ecommerce/filtersConfig';
import { ACTIVE_STATUS } from '@/model/modules/ecommerce/publisher/PublisherStatus';
import { COMMISSION_TYPES } from '@/model/modules/ecommerce/publisher/CommissionTypes';
import { COMMISSION_OWNERS } from '@/model/modules/ecommerce/publisher/CommissionOwners';
import CurrencySelector from '@/components/atoms/CurrencySelector/CurrencySelector';
import AsterixTagCreator from '@/components/molecules/shared/AsterixTagCreator';
import { deepClone } from '@/utils/deepClone';

export default {
  name: 'AddPublisherModal',
  components: {
    AsterixRadioGroup,
    AsterixRadioInput,
    AsterixInput,
    AsterixAsyncSelect,
    AsterixFormModal,
    CurrencySelector,
    AsterixTagCreator,
  },
  props: {
    offerPublishers: {
      type: Array,
      default: () => [],
    },
    publishers: {
      type: Array,
      default: () => [],
    },
    offerPublisher: {
      type: Object,
      default: () => null,
    },
    isPercentageOffer: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    selectedPublisher: null,
    selectedCommissionType: COMMISSION_TYPES.DEFAULT,
    commissionTypes: COMMISSION_TYPES,
    percentCommission: null,
    isSelectOpened: false,
    fixPrice: { value: null, currency: null },
    newPublisherRoute,
    subPublisherLockedList: null,
  }),
  computed: {
    ...mapGetters({ activeClient: ACTIVE_CLIENT }),
    title() {
      return this.editingPublisher ? 'Edit publisher' : 'Add publisher';
    },
    disabledInput() {
      return {
        defaultCommission: this.selectedCommissionType !== COMMISSION_TYPES.DEFAULT,
        percentCommission: this.selectedCommissionType !== COMMISSION_TYPES.PERCENT,
        fixPrice: this.selectedCommissionType !== COMMISSION_TYPES.FIX_PRICE,
      };
    },
    isCommissionValid() {
      let isValid = false;
      if (this.isPercentCommission) {
        isValid = !!this.percentCommission && this.percentCommission > 0 && this.percentCommission <= 100;
      } else if (this.isFixPriceCommission) {
        isValid = !!this.fixPrice.value && this.fixPrice.value > 0;
      } else if (this.selectedCommissionType === this.commissionTypes.DEFAULT) {
        isValid = true;
      }
      return isValid;
    },
    isFormValid() {
      return !!this.selectedPublisher && this.isCommissionValid;
    },
    editingPublisher() {
      return !!this.offerPublisher;
    },
    currentCommissionType() {
      return this.selectedPublisher.selectedCommissionType;
    },
    currentCommissionOwner() {
      let owner = COMMISSION_OWNERS.NONE;
      if (this.selectedCommissionType === COMMISSION_TYPES.FIX_PRICE) {
        owner = COMMISSION_OWNERS.PUBLISHER;
      } else if (this.selectedCommissionType === COMMISSION_TYPES.PERCENT) {
        owner = COMMISSION_OWNERS.OUR;
      }
      return owner;
    },
    isPercentCommission() {
      return this.selectedCommissionType === COMMISSION_TYPES.PERCENT;
    },
    isFixPriceCommission() {
      return this.selectedCommissionType === COMMISSION_TYPES.FIX_PRICE;
    },
    isDefaultCommission() {
      return this.selectedCommissionType === COMMISSION_TYPES.DEFAULT;
    },
  },
  created() {
    if (this.editingPublisher) {
      this.setPublisher();
      this.selectedCommissionType = this.offerPublisher.commissionType;
    }

    this.loadSubPublisherLockedList();
    this.updatePriceAndCommissionByOfferPublisher();
  },
  methods: {
    setPublisher() {
      this.selectedPublisher = this.offerPublisher.publisher;
    },
    onAddPublisherSubmit() {
      let commissionValue;
      if (this.selectedCommissionType === this.commissionTypes.PERCENT) {
        commissionValue = this.percentCommission;
      } else if (this.selectedCommissionType === this.commissionTypes.FIX_PRICE) {
        commissionValue = this.fixPrice.value;
      } else {
        commissionValue = this.selectedPublisher.commissionValue;
      }
      const publisherToAdd = new OfferPublisher(
        null,
        commissionValue,
        this.selectedCommissionType,
        this.currentCommissionOwner,
        null,
        null,
        this.selectedPublisher ? this.selectedPublisher.id : null,
        this.offerPublisher ? this.offerPublisher.toggle : 'ACTIVE',
        null,
        null,
        this.offerPublisher ? this.offerPublisher.test : null,
        this.subPublisherLockedList
      );

      this.$emit('confirm', publisherToAdd);
    },
    resetPriceAndCommission() {
      this.updatePriceAndCommissionByOfferPublisher();
    },
    updatePriceAndCommissionByOfferPublisher() {
      const fixPrice = {
        value:
          this.isFixPriceCommission && this.offerPublisher?.commissionType === this.selectedCommissionType
            ? this.offerPublisher.commissionValue
            : null,
        currency: 'EUR',
      };
      let percentCommission;
      if (this.isPercentageOffer) {
        percentCommission =
          this.isPercentCommission && this.offerPublisher?.commissionType === this.selectedCommissionType
            ? parseFloat(this.offerPublisher.publisher_price.slice(0, -1)).toFixed(2)
            : null;
      } else {
        percentCommission =
          this.isPercentCommission && this.offerPublisher?.commissionType === this.selectedCommissionType
            ? this.offerPublisher.commissionValue
            : null;
      }
      this.updatePriceAndCommission(fixPrice, percentCommission);
    },
    updatePriceAndCommission(fixPrice = { value: null, currency: null }, percentCommission = null) {
      this.percentCommission = percentCommission;
      this.fixPrice = fixPrice;
    },
    getPublishersService(params) {
      params.addFilter(FILTERS_CONFIG.PUBLISHER_STATUS.value, ACTIVE_STATUS.id);
      return getPublishers(params, this.activeClient.id);
    },
    expandModalHeight() {
      this.isSelectOpened = true;
    },
    collapseModalHeight() {
      this.isSelectOpened = false;
    },
    loadSubPublisherLockedList() {
      if (this.editingPublisher) {
        this.subPublisherLockedList = deepClone(this.offerPublisher.subPublishersLockedList);
      } else {
        this.subPublisherLockedList = [];
      }
    },
    onSubPublisherLockedChange(list) {
      this.subPublisherLockedList = list;
    },
  },
};
</script>
<style scoped>
::v-deep .forced-gray {
  color: #a0aec0 !important;
}
</style>
